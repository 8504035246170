import React, { useRef, useEffect, useState } from "react"
import { Link, useStaticQuery, graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import { LayoutGroup, motion } from "framer-motion"
import ClientSingle from "./clientSingleItem"
import Menu from "./menu"
const GridStyles = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 80vw;
  }

  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  grid-auto-rows: minmax(min-content, max-content);
  z-index: 2;
  padding-top: 100px;
  .list {
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      list-style: none;
      text-align: center;
      margin: 70px 0;
      @media (min-width: 768px) {
        margin: 80px 0;
      }
      @media (min-width: 1100px) {
        margin: 100px 0;
      }
      padding: 15px;
      display: flex;
      flex-direction: column;
      li {
        padding: 0;
        color: var(--white);
        margin: 10px 0;
        font-family: "PPneueregular";
        text-transform: uppercase;
        line-height: 1.4;

        @media (min-width: 1200px) {
          line-height: 5vw;
        }

        a {
          color: var(--white);
          text-decoration: none;
          &:hover {
            color: var(--pink);
          }
        }
      }
    }
  }
  position: relative;
  overflow: hidden;
  a {
    text-decoration: none;
    color: var(--color-link);
    outline: none;
    cursor: pointer;
  }

  a:hover,
  a:focus {
    color: var(--color-link-hover);
    outline: none;
  }
  .chefMenuWrap {
    padding: 10rem 0 20vh 0;
    grid-column: 1 / span 24;
    overflow: hidden;
  }
  .clientMenu {
    width: 100%;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    counter-reset: menucounter;

    //overflow: hidden;
  }

  .menu__item {
    position: relative;
    padding-left: 1rem;
    @media (min-width: 768px) {
      padding-left: 5rem;
    }
    // mix-blend-mode: hard-light;
  }
  /*
  .menu__item::before {
    counter-increment: menucounter;
    content: counters(menucounter, ".", decimal-leading-zero);
    position: absolute;
    left: 0;
    border-left: 3px solid currentColor;
    top: 20%;
    height: 60%;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    line-height: 1;
    font-weight: bold;
    opacity: 0;
    transform: translateX(-1rem);
    transition: transform 0.3s, opacity 0.3s;
  }

  .menu__item:hover::before {
    opacity: 1;
    transform: translateX(0);
  }
*/
  .menu__item-text {
    position: relative;
    cursor: pointer;
    padding: 0.5rem;
    display: block;
    overflow: hidden;
    font-size: 2.5rem;
  }

  .menu__item-textinner {
    display: block;

    font-weight: 300;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    //white-space: nowrap;
    color: var(--rust);
  }

  .js .menu__item-textinner {
    transform: translateY(100%);
  }

  .menu__item:hover .menu__item-textinner {
    color: var(--yellow);
    /*text-shadow: 1px 0 0 var(--beige), -1px 0 0 var(--beige),
      0 1px 0 var(--beige), 0 -1px 0 var(--beige), 1px 1px #fff,
      -1px -1px 0 var(--beige), 1px -1px 0 var(--beige), -1px 1px 0 var(--beige);
      */
  }

  .menu__item-sub {
    display: none;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
    //white-space: nowrap;
    align-items: center;
    position: relative;
    margin-left: 2rem;
    padding-left: 3rem;
    opacity: 0;
    transform: translateX(-1rem);
    transition: transform 0.3s, opacity 0.3s;
    mix-blend-mode: difference;
    color: var(--yellow);
  }

  .menu__item:hover .menu__item-sub {
    opacity: 1;
    transform: translateX(0);
  }

  .menu__item-sub::before {
    content: "";
    position: absolute;
    left: 0;
    top: 15%;
    width: 1px;
    height: 70%;
    background: currentColor;
    transform-origin: 0 100%;
    transform: rotate(22.5deg) scale3d(1, 0, 1);
    transition: transform 0.3s;
  }

  .menu__item:hover .menu__item-sub::before {
    transform: rotate(22.5deg) scale3d(1, 1, 1);
  }

  .hover-reveal {
    position: absolute;
    z-index: -1;
    width: 200px;
    height: 200px;
    @media (min-width: 1170px) {
      width: 600px;
      height: 420px;
    }
    top: -50%;
    left: 0;
    pointer-events: none;
    opacity: 0;
  }

  .hover-reveal__inner {
    overflow: hidden;
  }

  .hover-reveal__inner,
  .hover-reveal__img {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .hover-reveal__img {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .cursor {
    display: none;
  }

  .credits {
    align-self: flex-start;
    padding: 5rem 0 0 0;
  }

  .credits a {
    text-decoration: underline;
  }

  @media screen and (min-width: 1200px) {
    .menu {
      padding-top: 20vh;
    }
    .menu__item {
      display: inline-block;
      //
      justify-content: initial;
    }
    .menu__item-text {
      padding: 1vh 0;
      font-size: 4vw;
    }
    .menu__item-sub {
      display: flex;
    }
  }

  @media screen and (min-width: 1440px) {
    .menu {
      padding-top: 20vh;
    }
    .menu__item {
      display: inline-block;
      //
      justify-content: initial;
    }
    .menu__item-text {
      padding: 1vh 0;
      font-size: 80px;
    }
    .menu__item-sub {
      display: flex;
    }
  }
`

export default function ClientsList({ clients }) {
  useEffect(() => {
    // menu (<nav> element)
    const menuEl = document.querySelector(".clientMenu")
    /* brought it in */
    const imagesLoaded = require("imagesloaded")

    const { body } = document
    const preloader = selector =>
      new Promise(resolve => {
        const imgwrap = document.createElement("div")
        imgwrap.style.visibility = "hidden"
        body.appendChild(imgwrap)
        ;[...document.querySelectorAll(selector)].forEach(el => {
          const imgEl = document.createElement("img")
          imgEl.style.width = 0
          imgEl.src = el.dataset.img
          imgEl.className = "preload"
          imgwrap.appendChild(imgEl)
          // console.log(imgEl.src)
        })

        imagesLoaded(document.querySelectorAll(".preload"), () => {
          imgwrap.parentNode.removeChild(imgwrap)
          body.classList.remove("loading")
          resolve()
        })
      })

    // preload the images set as data attrs in the menu items
    preloader(".menu__item").then(() => {
      // initialize menu
      new Menu(menuEl)
    })
  }, [])

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.4,
        staggerDirection: 1,
      },
    },
  }
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  }

  return (
    <>
      <GridStyles>
        <div className="list">
          <motion.ul
            className="clientMenu"
            variants={container}
            initial="hidden"
            animate="show"
          >
            {clients.map((clientItem, i) => (
              <ClientSingle
                key={i}
                variants={item}
                to={"/clients/" + clientItem.slug.current}
                transition="cover"
                direction="down"
                client={clientItem.name}
                img={clientItem.image.asset.url}
              />
            ))}
          </motion.ul>
        </div>
      </GridStyles>
    </>
  )
}

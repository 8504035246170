import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"
// import splitting from 'splitting';

export default function ClientSingle({
  img,
  client,
  to,
  transition,
  direction,

  where,
}) {
  return (
    <>
      <motion.li data-img={img} className="menu__item">
        <span className="menu__item-text">
          <span className="menu__item-textinner">
            <div>
              <Link to={to}> {client}</Link>
            </div>
          </span>
        </span>
      </motion.li>
    </>
  )
}
